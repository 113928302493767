import * as ethers from 'ethers';
import { encodeFunctionData } from "viem";

var tixAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "initialSupply",
                "type": "uint256"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "previousAdminRole",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "newAdminRole",
                "type": "bytes32"
            }
        ],
        "name": "RoleAdminChanged",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleGranted",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "sender",
                "type": "address"
            }
        ],
        "name": "RoleRevoked",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "bytes32",
                "name": "_name",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "_destination",
                "type": "address"
            }
        ],
        "name": "ServiceRegistered",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "BALANCER",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "DEFAULT_ADMIN_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "MINTER_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "PAUSER_ROLE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "SERVICE_WORKER",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_counter",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_registrationFee",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32[]",
                "name": "names",
                "type": "bytes32[]"
            },
            {
                "internalType": "address[]",
                "name": "destinations",
                "type": "address[]"
            }
        ],
        "name": "areServicesRegistered",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "burn",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "burnFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "internalType": "uint8",
                "name": "",
                "type": "uint8"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "subtractedValue",
                "type": "uint256"
            }
        ],
        "name": "decreaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            }
        ],
        "name": "getRoleAdmin",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "uint256",
                "name": "index",
                "type": "uint256"
            }
        ],
        "name": "getRoleMember",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            }
        ],
        "name": "getRoleMemberCount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "name",
                "type": "bytes32"
            }
        ],
        "name": "getService",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "grantRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "hasRole",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "addedValue",
                "type": "uint256"
            }
        ],
        "name": "increaseAllowance",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            },
            {
                "internalType": "bytes",
                "name": "code",
                "type": "bytes"
            }
        ],
        "name": "registerService",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32[]",
                "name": "names",
                "type": "bytes32[]"
            },
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "destination",
                        "type": "address"
                    },
                    {
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    }
                ],
                "internalType": "struct TixToken.ServiceRegistration[]",
                "name": "destinations",
                "type": "tuple[]"
            }
        ],
        "name": "registerServices",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "renounceRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "name": "repository",
        "outputs": [
            {
                "internalType": "address",
                "name": "destination",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes32",
                "name": "role",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "revokeRole",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "fee",
                "type": "uint256"
            }
        ],
        "name": "setRegistrationFee",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "bytes4",
                "name": "interfaceId",
                "type": "bytes4"
            }
        ],
        "name": "supportsInterface",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

var upgradeableDeployer = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "spec",
                "type": "string"
            },
            {
                "internalType": "bytes",
                "name": "implementationCode",
                "type": "bytes"
            }
        ],
        "name": "deployOrUpgrade",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IServiceLocator",
                "name": "_serviceLocator",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "EnforcedPause",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "ExpectedPause",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            }
        ],
        "name": "OwnableInvalidOwner",
        "type": "error"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "OwnableUnauthorizedAccount",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "ReentrancyGuardReentrantCall",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "bytes32",
                "name": "_name",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "_destination",
                "type": "address"
            }
        ],
        "name": "ServiceDeployed",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "counter",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "serviceLocator",
        "outputs": [
            {
                "internalType": "contract IServiceLocator",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

var deployer = [
    {
        "inputs": [
            {
                "internalType": "string",
                "name": "name",
                "type": "string"
            },
            {
                "internalType": "string",
                "name": "spec",
                "type": "string"
            },
            {
                "internalType": "bytes",
                "name": "code",
                "type": "bytes"
            }
        ],
        "name": "deploy",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "contract IServiceLocator",
                "name": "_serviceLocator",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "previousOwner",
                "type": "address"
            },
            {
                "indexed": true,
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "OwnershipTransferred",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "pause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Paused",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "renounceOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "bytes32",
                "name": "_name",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "_destination",
                "type": "address"
            }
        ],
        "name": "ServiceDeployed",
        "type": "event"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "unpause",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "Unpaused",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "counter",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "paused",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const awaitTimeout = (delay, reason) =>
    new Promise((resolve, reject) =>
        setTimeout(
            () => (reason === undefined ? resolve() : reject(reason)),
            delay
        )
    );

var connectedAccount = "";

const wrapPromise = (promise, delay, reason) =>
    Promise.race([promise, awaitTimeout(delay, reason)]);

export async function initializeMetamask(net) {
    try {
        return { success: true, message: undefined, data: await getMetamaskState(net) };
    } catch (error) {
        console.error(error.message);
        return { success: true, message: "Error occured while connecting to wallet", data: undefined };
    }
}

async function ensureCorrectChain(destinationIsland) {
    const connectedAdapter = await MyLib.GetWeb3AuthProvider(null, destinationIsland);
    const chainId = connectedAdapter.chainId;
    console.log("checking if chain is " + destinationIsland.name + ", " + destinationIsland?.details?.chainId + ". Currently " + chainId);
    if (chainId !== destinationIsland?.details?.chainId) {
        console.log("change to " + destinationIsland.name);
        try {
            await MyLib.SwitchChain(destinationIsland);
        } catch (switchError) {
            console.log(switchError.message);
            try {
                await MyLib.AddChain(destinationIsland);

                return;
            } catch (addError) {
                console.log(addError.message);
            }
        }
    }
}

export async function initializeMetamaskWithTimeout(net) {
    try {
        //var timeout = 1;
        //if (window.ethereum._state.initialized) {
        var timeout = 60000;
        //}
        return await wrapPromise(initializeMetamask(net), timeout, "TIMEOUT");
    }
    catch (err) {
        return { success: false, message: err, data: await getMetamaskState(net) };
    }
}

export async function getMetamaskState(net) {
    var connected = await MyLib.InitAuth(null, net);
    let connectedAccount = undefined;
    try {
        if (MyLib.IsAASupported(net)) {
            const { connectedProvider } = await MyLib.InitializeAA(null, net);
            connectedAccount = await connectedProvider.getAddress();
        } else {
            let signer = await getSigner(net);
            connectedAccount = await signer.getAddress();
        }
    } catch (err) {
        console.error("Unexpected error when fetching address: " + err.message);
    }
    if (connected) {
        return { isConnected: true, isMobile: false, account: connectedAccount };
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // open the deeplink page 
        return { isConnected: false, isMobile: true, account: connectedAccount }
    } else {
        // install metamask
        return { isConnected: false, isMobile: false, account: connectedAccount }
    }
}

async function getWeb3Provider(net) {
    //if (typeof window.ethereum !== 'undefined') {
    //    console.log('MetaMask is installed!');
    //}
    await ensureCorrectChain(net);
    console.log("getting provider");
    //let provider = new ethers.BrowserProvider(window.ethereum);
    const provider = new ethers.BrowserProvider(await MyLib.GetWeb3AuthProvider());
    return provider;
}

async function getWeb3Signer(net) {
    let provider = await getProvider(net);
    console.log("getting signer");
    let signer = await provider.getSigner();
    return signer;
}

async function getInfuraProvider2(net) {
    console.log("change to MUMBAI");
    let dic_net = {
        name: 'maticmum',
        chainId: 80001,
        _defaultProvider: (providers) => new ethers.JsonRpcProvider('https://polygon-mumbai.infura.io/v3/7fbe72d5e8cc4e49af71d724db56d9b2')
    };
    return ethers.getDefaultProvider(dic_net);
}

//async function getInfuraProvider(net) {
//    return new ethers.InfuraProvider("maticmum", "7fbe72d5e8cc4e49af71d724db56d9b2");
//}

//async function getInfuraSigner(net) {
//    let provider = await getProvider(net);
//    //const walletSigner = wallet.connect(provider);
//    let accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
//    let walletAddress = accounts[0];    // first account in MetaMask
//    return await provider.getSigner(walletAddress);
//}

async function getProvider(net) {
    return await getWeb3Provider(net);
}

async function getSigner(net) {
    return await getWeb3Signer(net);
}

export async function deployTest(net) {
    // Create an instance of a Contract Factory
    let factory = new ethers.ContractFactory(testabi, testbytecode, wallet);
    let contract = await factory.deploy("Hello World");
    console.log(contract.address);
    console.log(contract.deployTransaction.hash);
    await contract.deployed();

    return contract.address;
}

export async function getLatestEthereum(net, abiString, contractAddress, count) {
    for (var i = 0; i <= 4; i++) {
        const abi = JSON.parse(abiString);
        console.log("creating contract from abi");
        let provider = await getProvider(net);
        const contract = new ethers.Contract(contractAddress, abi, provider);
        console.log("getting latest " + count + " address: " + contractAddress);
        try {
            return { items: JSON.stringify(await getLatestPolygonInner(contract, count)), error: undefined };
        } catch (err) {
            console.error(err);
            if (err.message.includes('Transaction reverted without a reason') && i < 4) {
                console.info("waiting 200ms");
                await new Promise(resolve => setTimeout(resolve, 200));
                console.info("retrying");
                continue;
            }
            return { error: err.message, items: undefined };
        }
    }
}

export async function getPageEthereum(net, abiString, contractAddress, cursor, howMany, onlyMine, version) {
    for (var i = 0; i <= 4; i++) {
        const abi = JSON.parse(abiString);
        console.log("creating contract from abi");

        var ethersProvider = await MyLib.GetWeb3AuthProvider(null, net);

        if (ethersProvider != null) {
            await MyLib.AddChain(net);
        }

        const provider = ethersProvider == null ? new ethers.JsonRpcProvider(net.details.rpcUrls[0]) : new ethers.BrowserProvider(ethersProvider);

        let address = "0x0000000000000000000000000000000000000000";
        try {
            const signer = await provider.getSigner();
            address = await signer.getAddress();
        } catch { }

        const contract = new ethers.Contract(contractAddress, abi, provider);
        console.log("getting " + howMany + " items starting from " + cursor + " address: " + contractAddress);
        try {
            if (version == 2) {
                return { items: JSON.stringify(await getPagePolygonInnerV2(address, contract, cursor, howMany)), error: undefined };
            } else {
                return { items: JSON.stringify(await getPagePolygonInner(address, contract, cursor, howMany, onlyMine)), error: undefined };
            }
        } catch (err) {
            console.error(err);
            if (err.message.includes('Transaction reverted without a reason') && i < 4) {
                console.info("waiting 200ms");
                await new Promise(resolve => setTimeout(resolve, 200));
                console.info("retrying");
                continue;
            }
            return { error: err.message, items: undefined };
        }
    }
}

export async function getVerifiedAccount(net, payload) {
    //try {
    //	// Get the signer
    //	let signer = await getWeb3Signer(net);

    //	// Sign the payload
    //	let signature = await signer.signMessage(payload);

    //	// Recover the signer's address from the signature
    //	let verifiedAccount = ethers.utils.verifyMessage(payload, signature);

    //	// Return both the signature and the verified account
    //	return {
    //		error: undefined,
    //		account: verifiedAccount,
    //		signature: signature
    //	};
    //} catch (error) {
    //	return { error: error.message, account: undefined, signature: undefined };
    //}
}

export async function loadEthereum(net, abiString, contractAddress, id) {
    for (var i = 0; i <= 4; i++) {
        const abi = JSON.parse(abiString);
        console.log("creating contract from abi");

        var ethersProvider = await MyLib.GetWeb3AuthProvider();

        if (ethersProvider != null) {
            await MyLib.AddChain(net);
        }

        const provider = ethersProvider == null ? new ethers.JsonRpcProvider(net.details.rpcUrls[0]) : new ethers.BrowserProvider(ethersProvider);


        const contract = new ethers.Contract(contractAddress, abi, provider);
        console.log("loading id " + id);
        try {
            return { item: JSON.stringify(await loadPolygonInner(contract, id)), error: undefined };
        } catch (err) {
            console.error(err);
            if (err.message.includes('Transaction reverted without a reason') && i < 4) {
                console.info("retrying");
                await new Promise(resolve => setTimeout(resolve, 200));
                console.info("retrying");
                continue;
            }
            return { error: err.message, item: undefined };
        }
    }
}

async function loadPolygonInner(contract, id) {
    var itemRaw = await contract.getItem(id);
    var item = convertBigIntToString(itemRaw.toObject());
    return item;
}

async function getLatestPolygonInner(contract, count) {
    var latest = await contract.getLatest(count);
    var latestArr = [];
    for (var i = 0; i < latest.length; i++) {
        var item = latest[i];
        var itemObj = convertBigIntToString(item.toObject());
        latestArr.push(itemObj);
    }
    return latestArr;
}

async function getPagePolygonInner(address, contract, cursor, howMany, onlyMine) {

    var latest = await contract.getPage(cursor, howMany, onlyMine, { "from": address });
    var latestArr = [];
    for (var i = 0; i < latest.length; i++) {
        var item = latest[i];
        var itemObj = convertBigIntToString(item.toObject());
        latestArr.push(itemObj);
    }
    return latestArr;
}

async function getPagePolygonInnerV2(address, contract, cursor, howMany) {

    var latest = await contract.getPage(cursor, howMany, { "from": address });
    var latestArr = [];
    for (var i = 0; i < latest.length; i++) {
        var item = latest[i];
        var itemObj = convertBigIntToString(item.toObject());
        latestArr.push(itemObj);
    }
    return latestArr;
}

function convertBigIntToString(obj) {
    for (const key in obj) {
        if (typeof obj[key] === 'bigint') {
            // Convert bigint to string
            obj[key] = obj[key].toString();
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Recursively apply to nested objects
            convertBigIntToString(obj[key]);
        }
    }
    return obj;
}

export async function runCurrencyMethodEthereum(net, contractAddress, methodName, parameters, isAA) {
    return await runMethodEthereum(net, erc20Abi, contractAddress, methodName, parameters, null, null, null, isAA);
}

export async function deploy2(net, bytecode, name, spec, executeAA, upgradeable) {
    if (net.version >= 3 && upgradeable) {
        var ver2Adr = await runMethodEthereum(net, upgradeableDeployer, net.upgradeableDeployer, "deployOrUpgrade", { name: name, spec: spec, implementationCode: "0x" + bytecode.object }, null, null, 7000000, executeAA);
        return ver2Adr;
    }
    else if (net.version >= 2) {
        var ver2Adr = await runMethodEthereum(net, deployer, net.deployer, "deploy", { name: name, spec: spec, code: "0x" + bytecode.object }, null, null, 7000000, executeAA);
        return ver2Adr;
    }
    var address = await runMethodEthereum(net, tixAbi, net.tix, "registerService", { name: name, code: "0x" + bytecode.object }, null, null, 7000000, executeAA);
    return address;
}
function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

async function fetchGasPrice(net) {
    try {
        const response = await fetch(net.gasStationUrl);
        const data = await response.json();
        // Assume 'standard' gas price is to be used
        const gasPrice = {
            maxPriorityFeePerGas: ethers.parseUnits(data.standard.maxPriorityFee.toString(), 'gwei'),
            maxFeePerGas: ethers.parseUnits(data.standard.maxFee.toString(), 'gwei')
        };
        return gasPrice;
    } catch (err) {
        console.error("Failed to fetch gas price", err);
        // Fallback gas price in case of error
        return {
            maxPriorityFeePerGas: ethers.parseUnits('2', 'gwei'), // Example fallback
            maxFeePerGas: ethers.parseUnits('50', 'gwei') // Example fallback
        };
    }
}

export async function runMethodEthereum(net, abiString, contractAddress, methodName, parameters, deposit, id, gasLimit, executeAA) {
    var abi = abiString;
    if (typeof abi === "string") {
        abi = JSON.parse(abiString);
    }
    console.log("creating contract from abi");
    try {
        parameters = contaminateArray(parameters);

        var receipt;
        let contract = new ethers.Contract(contractAddress, abi, new ethers.JsonRpcProvider(net.details.rpcUrls[0]));

        if (executeAA && MyLib.IsAASupported(net)) {
            const { connectedProvider } = await MyLib.InitializeAA(null, net);

            const encodedFunctionCall = encodeFunctionData({
                abi: abi,
                functionName: methodName,
                args: parameters,
            });

            let depositValue = (deposit != undefined && deposit != null ? BigInt(deposit) : null);
            const uoHash = await connectedProvider.sendUserOperation({
                uo:
                {
                    target: contractAddress,
                    data: encodedFunctionCall,
                    value: depositValue
                }
            });

            let txHash;
            let error;
            for (let tries = 0; tries < 4; tries++) {
                if (error != undefined && error != null) {
                    await sleep(1500);
                }
                try {
                    error = null;
                    txHash = await connectedProvider.waitForUserOperationTransaction({ hash: uoHash.hash });
                    continue;
                } catch (e) {
                    error = e;
                }
            }
            if (error != undefined || error != null) {
                console.error("Error executing user op:", e);
                throw e;
            }

            receipt = await new ethers.JsonRpcProvider(net.details.rpcUrls[0]).getTransactionReceipt(txHash);
        } else {
            try {
                var signer = await getSigner(net);
                contract = new ethers.Contract(contractAddress, abi, signer);
            } catch (err) {
                console.log("Failed to load signer, using provider");
            }

            let adapter = await MyLib.GetWeb3AuthAdapter();

            if (executeAA && adapter?.name != "metamask") {
                return { error: "Please connect Metamask to execute blockchain transactions.", item: undefined };
            }

            if (gasLimit == null) {
                gasLimit = 7000000;
            }
            if (net.gasStationUrl) {
                const gasPrice = await fetchGasPrice(net);
                if (deposit != null) {
                    parameters.push({
                        value: deposit,
                        maxPriorityFeePerGas: gasPrice.maxPriorityFeePerGas,
                        maxFeePerGas: gasPrice.maxFeePerGas
                    });
                }
                else if (gasLimit != null) {
                    parameters.push({
                        maxPriorityFeePerGas: gasPrice.maxPriorityFeePerGas,
                        maxFeePerGas: gasPrice.maxFeePerGas
                    });
                }
            } else {
                if (deposit != null) {
                    parameters.push({ value: deposit, gasLimit: gasLimit });
                }
                else if (gasLimit != null) {
                    parameters.push({ gasLimit: gasLimit });
                }
            }
            let methodToRun = contract[methodName];

            if (typeof methodToRun === 'undefined') {
                // Return an error object without throwing
                return { error: `Method "${methodName}" not found in the contract ABI. Check if it has been renamed since the deployment`, item: undefined };
            }
            var itemTransaction = await methodToRun.apply(contract, parameters);
            if (typeof itemTransaction["wait"] !== 'function') {
                return { item: itemTransaction.toString(), error: undefined };
            }
            receipt = await itemTransaction.wait();
        }
        console.log(receipt.logs);

        let logs = [];
        // Event signature hash for UserOperationEvent
        const userOperationEventSignature = "0x49628fd1471006c1482da88028e9ce4dbb080b815c9b0344d39e5a8e6ec1419f";

        // Filter out UserOperationEvent logs
        const filteredLogs = receipt.logs.filter(log => log.topics[0] !== userOperationEventSignature);

        for (var logNr = 0; logNr < filteredLogs.length; logNr++) {
            try {
                let log = contract.interface.parseLog(filteredLogs[logNr]);
                if (log) {
                    logs.push(log);
                }
            } catch (logErr) {
                console.log("EthersJs issue, log processing failed: " + err.message)
                console.log(JSON.stringify(filteredLogs[logNr]))
            }
        }

        const itemUpdated = logs.find(event => event.eventName === 'ItemUpdated' || event.name === 'ItemUpdated');
        if (itemUpdated != null) {
            id = itemUpdated.args["_id"];
        }

        const serviceRegistered = logs.find(event => event.eventName === 'ServiceRegistered' || event.name === 'ServiceRegistered');
        if (serviceRegistered != null) {
            var destination = serviceRegistered.args["_destination"];
            return { error: undefined, item: destination };
        }

        const serviceDeployed = logs.find(event => event.eventName === 'ServiceDeployed' || event.name === 'ServiceDeployed');
        if (serviceDeployed != null) {
            var destination = serviceDeployed.args["_destination"];
            return { error: undefined, item: destination };
        }

        if (id == null || id == "") {
            return { item: undefined, error: undefined };
        }
        else if (id === 0) {
            var list = (await getLatestPolygonInner(contract, 1));
            return { item: JSON.stringify(list[0]), error: undefined };

        } else {
            return { item: JSON.stringify(await loadPolygonInner(contract, id)), error: undefined };
        }
    } catch (err) {
        console.error(err);
        return { error: err.message, item: undefined };
    }
}

function contaminateArray(obj) {
    let retObj = [];
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop) && !isNumeric(prop)) {
            var value = obj[prop];
            if (value?.toString().startsWith("bignumber_")) {
                let possibleNewValue = value.replace("bignumber_", "");
                //Push as string
                retObj.push(possibleNewValue);
            } else {
                retObj.push(value);
            }
        }
    }
    return retObj;
}

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export async function deploy(net, abi, bytecode) {
    let signer = await getSigner(net);
    // Create an instance of a Contract Factory
    console.log("creating contract factory");
    let factory = new ethers.ContractFactory(JSON.parse(abi), bytecode, signer);

    // Notice we pass in "Hello World" as the parameter to the constructor
    console.log("deploying");
    let contract = await factory.deploy({ gasLimit: 7000000 });
    console.log(contract.address);
    console.log(contract.deployTransaction.hash);
    await contract.deployed();

    return contract.address;
}

const erc20Abi = [
    {
        "constant": true,
        "inputs": [],
        "name": "name",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [
            {
                "name": "",
                "type": "uint8"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "name": "balance",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [],
        "name": "symbol",
        "outputs": [
            {
                "name": "",
                "type": "string"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "outputs": [
            {
                "name": "",
                "type": "bool"
            }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "constant": true,
        "inputs": [
            {
                "name": "_owner",
                "type": "address"
            },
            {
                "name": "_spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "name": "",
                "type": "uint256"
            }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "payable": true,
        "stateMutability": "payable",
        "type": "fallback"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "spender",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "to",
                "type": "address"
            },
            {
                "indexed": false,
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "event"
    }
];